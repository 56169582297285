import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { JwtResponseDto } from '@portal/shared/utils';

import { FeatureActions } from './feature.actions';

@Injectable()
export class FeatureFacade {
    private readonly store = inject(Store);

    userLogin(jwt: JwtResponseDto): void {
        this.store.dispatch(FeatureActions.persistUserAuth({ jwt }));
    }

    userLogout(): void {
        this.store.dispatch(FeatureActions.purgeUserAuth());
    }
}
