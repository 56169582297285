import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthenticationResource, JwtResponseDto, LoginDto } from '@portal/shared/utils';

@Injectable()
export class LoginController {
    private readonly _resource: AuthenticationResource = inject(AuthenticationResource);

    login(loginDto: LoginDto): Observable<JwtResponseDto> {
        return this._resource.login(loginDto);
    }
}
